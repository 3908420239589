<template>
    <!-- 认证照片检查 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">系统监管</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">认证照片检查</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items: flex-start">
                    <div class="searchbox">
                        <div class="searchboxItem ci-full">
                            <span title="学员信息" class="itemLabel">学员信息:</span>
                            <el-select
                                v-model="searchForm.userId"
                                placeholder="请选择学员"
                                remote
                                size="small"
                                clearable
                                :remote-method="superUserSelect"
                                @visible-change="clearUserSearchModel">
                                <template>
                                    <div class="select-header">
                                        <span style=" color: #8492a6; float: left; font-size: 13px; width: 100px;">学员姓名</span>
                                        <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 140px;">身份证号码</span>
                                        <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 100px;">学员电话</span>
                                    </div>
                                    <div class="select-header">
                                        <el-input
                                            v-model="searchInputForm.userName"
                                            v-on:input="superUserSelect"
                                            type="text"
                                            size="small"
                                            placeholder="学员姓名"
                                            clearable
                                            style="width: 100px"
                                        />
                                        <el-input
                                            v-model="searchInputForm.idcard"
                                            v-on:input="superUserSelect"
                                            type="text"
                                            size="small"
                                            placeholder="身份证号码"
                                            clearable
                                            style="margin-left: 50px; width: 140px"
                                        />
                                        <el-input
                                            v-model="searchInputForm.mobile"
                                            v-on:input="superUserSelect"
                                            type="text"
                                            size="small"
                                            placeholder="学员电话"
                                            clearable
                                            style="margin-left: 50px; width: 100px"
                                        />
                                    </div>
                                </template>
                                <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userId">
                                    <span style="width: 100px; font-size: 13px">{{item.userName}}</span>
                                    <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                                    <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem ci-full">
                            <span title="班级信息" class="itemLabel">班级信息:</span>
                            <el-select
                                v-model="searchForm.projectId"
                                placeholder="请选择班级"
                                remote
                                size="small"
                                clearable
                                :remote-method="superProjectSelect"
                                @visible-change="clearProjectSearchModel">
                                <template>
                                    <div class="select-header">
                                        <span style=" color: #8492a6; float: left; font-size: 13px; width: 150px;">班级编号</span>
                                        <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 250px;">班级名称</span>
                                    </div>
                                    <div class="select-header">
                                        <el-input
                                            v-model="searchInputForm.projectCode"
                                            v-on:input="superProjectSelect"
                                            type="text"
                                            size="small"
                                            placeholder="班级编号"
                                            clearable
                                            style="width: 150px"
                                        />
                                        <el-input
                                            v-model="searchInputForm.projectName"
                                            v-on:input="superProjectSelect"
                                            type="text"
                                            size="small"
                                            placeholder="请输入班级名称"
                                            clearable
                                            style="margin-left: 50px; width: 250px"
                                        />
                                    </div>
                                </template>
                                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName" :value="item.projectId">
                                    <span style="width: 150px; font-size: 13px">{{item.projectCode}}</span>
                                    <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem ci-full">
                            <span title="机构名称" class="itemLabel">机构名称:</span>
                            <el-select
                                v-model="searchForm.compId"
                                size="small"
                                remote
                                :remote-method="getMmechanismInfo"
                                filterable
                                clearable
                                placeholder="请输入两个字搜索"
                            >
                                <el-option
                                    v-for="item in mechanismList"
                                    :key="item.compId"
                                    :label="item.compName"
                                    :value="item.compId"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="btnBox">
                            <el-button class="bgc-bv" @click="searchData(1)">查询</el-button>
                        </div>
                    </div>
                    <div class="searchbox" style="margin-top: 10px">
                        <div class="searchboxItem ci-full">
                            <span title="认证时间" class="itemLabel">认证时间:</span>
                            <el-date-picker
                                @change="dataPickerChange"
                                v-model="searchInputForm.certificationTime"
                                size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00','23:59:59']">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <!-- 照片列表区域 -->
                <div class="framePage-scroll">
                    <div class="img-box">
                        <div class="img-wrap" @click="preview(item)" v-for="item in userImgList" :key="item.faceId">
                            <el-image :src="item.approveFace">
                                <div slot="placeholder" class="image-slot">
                                    加载中<span class="dot">...</span>
                                </div>
                            </el-image>
                        </div>
                    </div>
                </div>
                <PageNum
                    style="margin-top:20px;"
                    :apiData="pagination"
                    @sizeChange="sizeChange"
                    @getData="currentChange"
                />
            </div>
        </div>

        <!-- 查看原图对话框 -->
        <el-dialog
            class="imgDialog"
            title="查看认证原图"
            :visible.sync="dialogVisible"
            width="600px">
            <div class="detail">
                <div class="left">
                    <el-image :src="authenticationInfo.headPath"/>
                </div>
                <div class="right">
                    <div>班级编码：{{authenticationInfo.projectCode}}</div>
                    <div>班级名称：{{authenticationInfo.projectName}}</div>
                    <div>开班机构：{{authenticationInfo.compName}}</div>
                    <div>姓名：{{authenticationInfo.userName}}</div>
                    <div>身份证号：{{authenticationInfo.idcard}}</div>
                    <div>手机号：{{authenticationInfo.mobile}}</div>
                    <el-button size="small" class="bgc-bv" @click="handleImg">处理照片</el-button>
                </div>
            </div>
            <div class="originalImg">
                <el-image :src="previewImg"></el-image>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import PageNum from "@/components/PageNum.vue";
    import moment from "moment";
    export default {
        name:"inspectionOfCertifiedPhotos",
        components:{
            PageNum
        },
        created(){
            this.assignmentDefaultTime();
        },
        data(){
            return{
                // 分页器数据
                pagination:{
                    current:1,
                    size:100,
                    total:0
                },
                // 查询表单
                searchForm:{
                    userId:"",//学员id
                    projectId:"",//班级id
                    compId:"",//机构id
                    startTime:"",//认证开始时间
                    endTime:"",//认证结束时间
                },
                // 查询表单输入的内容
                searchInputForm:{
                    userName:"",//学员姓名
                    idcard:"",//身份证号
                    mobile:"",//手机号
                    projectName:"",//班级名称
                    projectCode:"",//班级编号
                    certificationTime:[],//认证时间
                },
                // 日期时间选择器默认时间
                defaultTime:['17:00:00','23:59:59'],
                // 机构列表
                mechanismList:[],
                // 学员列表
                userList:[{}],
                // 班级列表
                projectList:[{}],
                // 学员照片列表
                userImgList:[],
                // 对话框可见性
                dialogVisible:false,
                // 认证照片缩略图
                previewImg:"",
                // 认证信息
                authenticationInfo:{}
            }
        },
        methods:{
            // 赋值时间选择器默认时间
            assignmentDefaultTime(){
                const {searchInputForm,searchForm}=this;
                // 获取昨天的日期并格式化
                const startDate=moment().add(-1,"d").format("YYYY-MM-DD")+" 00:00:00";
                // 获取今天的日期并格式化
                const endDate=moment().add(-1,"d").format("YYYY-MM-DD")+" 23:59:59";
                // 赋值
                searchInputForm.certificationTime=[startDate,endDate];
                searchForm.startTime=startDate;
                searchForm.endTime=endDate;
            },
            // 查询按钮回调
            searchData(query){
                const {searchForm,pagination}=this;
                if(typeof query==="number") pagination.current=query;
                // 判断查询有没有满足条件
                if(!searchForm.userId && !searchForm.projectId && !searchForm.compId){
                    this.$message.warning("请选择学员信息、班级信息或机构名称进行查询");
                    return;
                }
                this.$post("/biz/userface/inspect/pageList",{
                    ...searchForm,
                    pageNum:pagination.current,
                    pageSize:pagination.size
                }).then(res=>{
                    this.userImgList=[];
                    setTimeout(()=>{
                        this.userImgList=res?.data?.list||[];
                        pagination.total=res?.data?.total||0;
                    },300)
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 获取机构数据
            getMmechanismInfo(compName){
                this.$post("/sys/company/queryCompanyList",{
                    compName
                }).then(res=>{
                    this.mechanismList=res?.data||[];
                }).catch(e=>{
                    console.error("获取机构信息出错",e);
                });
            },
            // 搜索学员
            superUserSelect() {
                const {searchInputForm,searchForm}=this;
                this.$post("/biz/user/superUserSelect",{
                    pageNum: 1, //每次都只要第一页
                    pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
                    ...searchInputForm,
                    projectId:searchForm.projectId,
                },3000,false).then((res) => {
                    if (res.data.list.length === 0) {
                        this.userList = [{}];
                    } else {
                        this.userList = res.data.list;
                    }
                });
            },
            // 清除学员搜索信息
            clearUserSearchModel(e){
                const {searchInputForm}=this;
                if(e){
                    searchInputForm.userName="";
                    searchInputForm.idcard="";
                    searchInputForm.mobile="";
                    this.superUserSelect();
                }
            },
            // 搜索班级
            superProjectSelect() {
                const {searchInputForm,searchForm}=this;
                this.$post("/biz/project/superSelectProject",{
                    pageNum: 1, //每次都只要第一页
                    pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
                    projectName: searchInputForm.projectName,
                    projectCode: searchInputForm.projectCode,
                    userId: searchForm.userId,
                },3000,false).then((res) => {
                    if (res.data.list.length === 0) {
                        this.projectList = [{}];
                    } else {
                        this.projectList = res.data.list;
                    }
                });
            },
            // 清除班级搜索信息
            clearProjectSearchModel(e) {
                const {searchInputForm}=this;
                if(e){
                    searchInputForm.projectName="";
                    searchInputForm.projectCode="";
                    this.superProjectSelect();
                }
            },
            // 认证时间改变
            dataPickerChange(value){
                const {searchForm}=this;
                if(value){
                    searchForm.startTime=value[0];
                    searchForm.endTime=value[1];
                }else{
                    searchForm.startTime="";
                    searchForm.endTime="";
                }
            },
            // 查看照片
            preview(item){
                this.previewImg=item.approveFace;
                this.$post("/biz/userface/inspect/indexInfo",{
                    faceId:item.faceId
                }).then(res=>{
                    this.authenticationInfo=res?.data||{}
                    this.dialogVisible=true;
                }).catch(e=>{
                    console.error("获取认证信息出错",e);
                    this.dialogVisible=false;
                });
            },
            // 处理照片
            handleImg(){
                const {authenticationInfo,pagination}=this;
                const info={
                    idcard:authenticationInfo.idcard, // 身份证号
                    mobile:authenticationInfo.mobile, // 手机号
                    projectId:authenticationInfo.projectId, // 班级id
                    userId:authenticationInfo.userId, // 学员id
                    userName:authenticationInfo.userName, // 学员姓名
                    projectName:authenticationInfo.projectName, // 班级名称
                    pageNum:pagination.current, // 页码
                    pageSize:pagination.size, // 页大小
                    studyClearState:authenticationInfo.studyClearState, //是否可以清除记录
                    faceType:authenticationInfo.faceType, // 10/20 未认证;30/40/45 考试;80 每日
                }
                this.dialogVisible=false;
                this.$router.push({
                    path:"/web/studentInfoCertificationRecord",
                    query:{
                        info:JSON.stringify(info),
                        pageSource:"inspectionOfCertifiedPhotos",
                    }
                });
            },
            // 当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.searchData();
            },
            // 每页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.searchData();
            }
        },
        watch:{
            $route(newRoute,oldRoute){
                if(newRoute.path==="/web/inspectionOfCertifiedPhotos" && oldRoute.path==="/web/studentInfoCertificationRecord"){
                    this.searchData();
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .select-header {
        font-size: 14px;
        padding: 0 20px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #606266;
        height: 34px;
        line-height: 34px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
    }
    .framePage-body{
        .framePage-scroll{
            display: block !important;
            overflow: auto;
            .img-box{
                display: flex;
                flex-wrap: wrap;
                height: auto;
                .img-wrap{
                    width: 160px;
                    height: 160px;
                    margin: 5px;
                    flex-shrink: 0;
                    cursor: pointer;
                    .el-image{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    // 对话框
    .imgDialog{
        .detail{
            display: flex;
            .left{
                width: 150px;
                // height: 150px;
                flex-shrink: 0;
                .el-image{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .right{
                margin-left: 20px;
                flex-grow: 1;
                .el-button{
                    float: right;
                }
            }
        }
        .originalImg{
            margin-top: 20px;
            width: 100%;
            .el-image{
                display: block;
                width: 100%;
            }
        }
    }
</style>